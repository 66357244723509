/** @jsxImportSource react */
import clsx from 'clsx'
import FontAwesomeIcon from './FontAwesomeIcon'
import NoSSR from './NoSSR'
import { globalStore } from '$/store'
import { $userDiscounts } from '@store/user-discounts'
import { cart, removeCartItem, removeVirtualCartItem, updateCartItem, updateVirtualCartItem } from '$/store/cart'
import { useStore } from '@nanostores/react'
import { ReactComponent as CartIcon } from '$/icons/redesign/cart.svg'
import { Badge } from './Badge/Badge'
import FeaturedList from './FeaturedList'
import miniCartUpsellList from '$/data/upsell-list.json'

const ASSETS_URL = import.meta.env.PUBLIC_ASSETS_URL

function formatPrice(num: number) {
  return num % 1 != 0 ? num.toFixed(2) : num
}

function MiniCartProduct({
  product,
  productDiscountRate = 1,
  className,
}: PropsWithClassName<{
  product: CartItem
  productDiscountRate?: number
}>) {
  const { user } = useStore(globalStore)
  const url = `/product/${product.productId}/${product.slug}`
  const price = Number(product.price) * (product.quantity || 1) * productDiscountRate
  const isUser = !!user?.id
  const removeItem = isUser ? removeCartItem : removeVirtualCartItem
  const updateItem = isUser ? updateCartItem : updateVirtualCartItem
  const listingImage = product.listingImage.startsWith('https')
    ? product.listingImage
    : ASSETS_URL + product.listingImage

  const handleChange = async (e: any) => {
    const quantity = Number(e.target.value)
    const payload = {
      name: product.name,
      productId: product.productId,
      maxQuantity: product.maxQuantity,
      quantity,
      amount: Number(product.price),
      price: Number(product.price),
      type: product.type,
      slug: product.slug,
      shortDescription: product.shortDescription,
      listingImage: listingImage,
    }

    if (!quantity) {
      await removeItem({
        name: product.name,
        productId: product.productId,
        quantity,
        amount: Number(product.price),
      })
    } else {
      await updateItem(payload)
    }
  }

  return (
    <div
      className={clsx(
        'l-stack l-stack--sm u-flex u-flex-col u-justify-between u-overflow-hidden u-rounded-[8px] u-border u-bg-white',
        className,
      )}
    >
      <a href={url} className="l-stack u-px-2 u-py-1 u-text-center u-text-black hover:u-text-pink">
        <img src={listingImage} className="u-mx-auto u-max-w-[140px]" />
        <h2 className="u-text-[1rem] u-capitalize u-leading-tight desktop:u-text-[1.25rem]">{product.name}</h2>
        <p className="p1 u-max-w-[30ch] u-font-medium u-text-night">{product.shortDescription}</p>
      </a>
      <div className="p2 u-flex u-items-center u-border-t">
        <div className="u-w-3/4 u-p-2 u-text-left">${formatPrice(price)}</div>
        {product && product.maxQuantity ? (
          <select className="select u-w-1/2 u-border-l u-p-2" onChange={handleChange} value={product.quantity}>
            {Array.from(Array(product.maxQuantity + 1), (quantity, i) => (
              <option key={i} value={quantity}>
                {i}
              </option>
            ))}
          </select>
        ) : null}
      </div>
    </div>
  )
}

export default function MiniCart() {
  const { strikethroughPercentage } = useStore(globalStore)
  const { cartItems, totals } = useStore(cart)
  const cartCount = cartItems
    .filter((i) => ['original', 'variant'].includes(i.type))
    .reduce((acc, item) => acc + item.quantity, 0)
  const subtotal = Number(totals?.subtotal || 0)
  const shippingDiscount = 50 <= subtotal
  const shippingRemainder = shippingDiscount ? 0 : 50 - subtotal
  const buy3Remainder = 3 - cartCount
  const displayedCartItems = cartItems.filter((i) => ['original', 'variant'].includes(i.type) && !i.isOutOfStock)
  const { buy3DiscountPercentage, planDiscountPercentage } = useStore($userDiscounts)
  const productDiscountRate = strikethroughPercentage !== 1 ? 1 - strikethroughPercentage * 0.01 : 1

  return (
    <NoSSR>
      <>
        <div className={clsx('u-flex-1', cartCount ? '' : 'u-grid u-place-content-center')}>
          {cartCount ? (
            <>
              <div className={shippingDiscount ? 'u-rounded u-bg-black u-py-2 u-text-white' : ''}>
                <div className="p2 u-flex u-items-center u-justify-center u-font-medium u-leading-none">
                  <FontAwesomeIcon icon="truck" className="u-mr-2" />
                  {shippingDiscount
                    ? `You qualify for FREE shipping!`
                    : `Only $${shippingRemainder.toFixed(2)} away from FREE SHIPPING`}
                </div>
                {!shippingDiscount && (
                  <div className="u-mt-2 u-h-[4px] u-overflow-hidden u-rounded u-bg-black">
                    <div className="u-h-full u-bg-pink" style={{ width: (subtotal / 50) * 100 + '%' }}></div>
                  </div>
                )}
              </div>
              <div className="u-mt-4 u-grid u-grid-cols-2 u-gap-2 desktop:u-gap-6">
                {displayedCartItems.map((product, i) => (
                  <MiniCartProduct product={product} key={i} productDiscountRate={productDiscountRate} />
                ))}
                <div
                  className={clsx(
                    'p2 u-grid u-place-content-center u-rounded-lg u-bg-light u-p-4 u-font-medium u-text-night',
                    displayedCartItems.length % 2 === 0 ? 'u-col-span-2' : '',
                  )}
                >
                  {cartCount < 3 ? (
                    <div className="u-flex u-flex-col u-items-center u-text-center">
                      <Badge className="u-mb-2">Buy 3 Save {buy3DiscountPercentage}%</Badge>
                      Add {buy3Remainder} more {buy3Remainder !== 1 ? 'items' : 'item'} to save {buy3DiscountPercentage}
                      %!
                    </div>
                  ) : (
                    <a href="/products" className="u-inline-block">
                      <div className="u-mx-auto u-mb-2 u-grid u-h-8 u-w-8 u-place-content-center u-rounded u-bg-primary u-text-white">
                        <FontAwesomeIcon icon="plus" />
                      </div>
                      Add more items
                    </a>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="p2 u-flex u-min-h-[200px] u-flex-1 u-flex-col u-items-center u-justify-center u-font-medium">
              Your shopping bag is empty
              <CartIcon className="u-mt-6 u-text-outline" height="80" width="80" />
            </div>
          )}
        </div>
        {!!buy3Remainder && <FeaturedList title="Now Trending" products={miniCartUpsellList} />}
      </>
    </NoSSR>
  )
}
